<template>
  <div>
    <div class="electronicSignatureBox">
      <h2>电子签章工具-单机版</h2>
      <p>电子签章工具快速帮您完成电子文件签署，并保证电子文件的法律有效性和实现电子文件的不可篡改。与Ukey（类似于银行U盾，电子印章的载体）配合使用，插入Ukey数字证书即可完成签署流程。</p>
    </div>
    <div class="signatureTool">
      <h2>安全便捷的签章工具</h2>
      <h5>有数字证书就能签，告别繁琐注册开通步骤</h5>
      <div>
        <div class="toolIntroduction" v-for="(item, index) in demonstrate" :key="index">
          <img :src=" require(`@/assets/image/productCenter/`+item.icon)" alt="签章icon">
          <dl>
            <dt>{{item.name}}</dt>
            <dd>{{item.txt}}</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="ourAdvantage">
      <h2>我们的优势</h2>
      <h5>安全、合规、便捷的电子签章产品</h5>
      <div>
        <div class="advantagesIntroduction" v-for="(item, index) in product" :key="index">
          <dl>
            <dt>{{item.name}}</dt>
            <dd v-for="(txt, i) in item.superiority" :key="i">{{txt}}</dd>
          </dl>
        </div>
      </div>
    </div>
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      demonstrate: [
        {
          name: '多种签署方式',
          icon: 'introduce1.png',
          txt:
            '支持单页签、多页签、骑缝签等多种签章方式，符合用户物理用章习惯',
        },
        {
          name: '丰富的签署场景',
          icon: 'introduce2.png',
          txt:
            '满足公文签章、合同签章、标书盖章等用户多种电子文件的盖章诉求',
        },
        {
          name: '支持超大文件',
          icon: 'introduce3.png',
          txt:
            '能够快速实现对超过1G的超大文件进行电子盖章，无需打印、快递合同，盖得越多省得越多',
        },
        {
          name: '兼容多签章标准',
          icon: 'introduce4.png',
          txt:
            '签章标准符合《安全电子签章密码技术规范》以及兼容ISO 32000国际通用标准',
        },
        {
          name: '离线签署',
          icon: 'introduce5.png',
          txt:
            '实现无网络进行签章，有效解决离线环境下的盖章诉求，确保业务有序进行',
        },
      ],
      product: [
        {
          name: '安全稳定',
          superiority: [
            '数字证书、密钥、印章数据加密保存在Ukey中',
            'Ukey+PIN码双因子身份认证，有效防止非授权的使用',
            'Pin码基于物理芯片，具有自动锁死机制',
          ],
        },
        {
          name: '合法合规',
          superiority: [
            '硬件密码模块通过国家密码管理局的检测认证',
            'Ukey内置SM2数字证书并由豸信CA直接颁发',
            '签章标准符合GB 38540规范，利于互通',
          ],
        },
        {
          name: '灵活应用',
          superiority: [
            '无需对接业务系统，无需附加设备，插入Ukeyt直接使用',
            '体积小，重量轻，方便随身携带',
          ],
        },
        {
          name: '功能丰富',
          superiority: [
            '具备单页签章、多页签章、签骑缝章、验证等功能',
            '能够满足多种业务场景的签章需求',
          ],
        },
      ],
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.electronicSignatureBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 5.05rem 0.53rem 1.53rem;
  box-sizing: border-box;
  text-align: center;
  background: url('~@/assets/image/productCenter/electronicSignature.png') top
    left no-repeat;
  background-size: 100% 100%;
  img {
    width: 5.29rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.64rem 0 0.26rem;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
.signatureTool {
  padding: 0.9rem 0.32rem 0;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.16rem;
    text-align: center;
  }
  h5 {
    font-size: 0.26rem;
    font-weight: 400;
    color: #98a4b1;
    line-height: 0.37rem;
    margin-bottom: 0.64rem;
    text-align: center;
  }
}
.toolIntroduction {
  width: 6.86rem;
  height: 2.04rem;
  background: #e9f1fe;
  border-radius: 0.02rem;
  padding: 0.32rem;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 0.4rem;
  img {
    width: 0.6rem;
    height: 0.58rem;
    flex-shrink: 0;
  }
  dl {
    margin-left: 0.24rem;
  }
  dt {
    font-size: 0.28rem;
    font-weight: bold;
    color: #293b54;
    line-height: 0.4rem;
    margin-bottom: 0.24rem;
  }
  dd {
    font-size: 0.26rem;
    color: #293b54;
    line-height: 0.33rem;
  }
}
.ourAdvantage {
  padding: 0.5rem 0.6rem 0;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #2d3b52;
    line-height: 0.67rem;
    margin-bottom: 0.16rem;
    text-align: center;
  }
  h5 {
    font-size: 0.26rem;
    font-weight: 400;
    color: #95a5b3;
    line-height: 0.37rem;
    margin-bottom: 0.64rem;
    text-align: center;
  }
}
.advantagesIntroduction {
  width: 6.3rem;
  height: 3.68rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 0.08rem 0.24rem 0px rgba(133, 167, 222, 0.29);
  border-radius: 0.08rem;
  border: 1px solid #ffffff;
  padding: 0.32rem;
  box-sizing: border-box;
  margin-bottom: 0.4rem;
  &:last-child {
    margin-bottom: 0.9rem;
  }
  dt {
    font-size: 0.32rem;
    color: #1c67f3;
    line-height: 0.45rem;
    margin-bottom: 0.32rem;
  }
  dd {
    font-size: 0.26rem;
    color: #303b50;
    line-height: 0.37rem;
    margin-bottom: 0.24rem;
    padding-left: 0.32rem;
    position: relative;
    &::before {
      content: '';
      width: 0.1rem;
      height: 0.1rem;
      background: #303b50;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -0.06rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>